





























import { Component, Vue } from "vue-property-decorator";
import NinetyNineLogo from "@/components/base/NinetyNineLogo.vue";
import RegisterContainer from "@/components/containers/RegisterContainer.vue";

@Component({
  components: {
    NinetyNineLogo,
    RegisterContainer,
  },
})
export default class ForgotPasswordLayout extends Vue {
  get secondaryComponent(): unknown {
    return () => import("@/components/forgot-password/ForgotPasswordFaq.vue");
  }

  get trackingNeedHelpLabel(): string {
    if (this.$route.name?.includes("email")) return "email";
    else if (this.$route.name?.includes("phone")) return "phone";
    else if (this.$route.name?.includes("sms")) return "code";
    else if (this.$route.name?.includes("password")) return "password";
    else return "ignore";
  }
}
