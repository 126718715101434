






import { Component, Vue } from "vue-property-decorator";

@Component
export default class RegisterView extends Vue {
  email = "";

  nextStep(): void {
    this.$store.dispatch("register/testAction");
  }
}
